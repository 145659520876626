const SESSION_MESSAGE_TYPE = {
    img: '[图片]',
    file: '[文件]',
    audio: '[语音]',
    loc: '[位置]',
    goods: '[商品]',
    order: '[订单]',
    StatusOrder: '[销售订单]'
}

const CUSTOM_TYPE = {
    image: '图片',
    audio: '语音',
    video: '视频',
    file: '文件',
    goods: '商品',
    StatusOrder: '销售订单',
    order: '订单',
    inform: '系统通知'
}
const ALL_MESSAGE_TYPE = {
    TEXT: 'txt',
    IMAGE: 'img',
    AUDIO: 'audio',
    LOCAL: 'loc',
    VIDEO: 'video',
    FILE: 'file',
    CUSTOM: 'custom',
    CMD: 'cmd',
    INFORM: 'inform', //这个类型不在环信消息类型内，属于自己定义的一种系统通知类的消息。
    GOODS: 'goods',
    ORDER: 'order',
    STATUSORDER: 'StatusOrder'
}
const CHAT_TYPE = {
    SINGLE: 'singleChat',
    GROUP: 'groupChat'
}

export default {
    SESSION_MESSAGE_TYPE,
    CUSTOM_TYPE,
    ALL_MESSAGE_TYPE,
    CHAT_TYPE
}
